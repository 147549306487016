import React, { useContext, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FaCloudUploadAlt } from 'react-icons/fa';
import Modal from 'react-modal';
import { Button } from 'reactstrap';
import { DialogContext } from '../../store/context/DialogContext';

// React Modal styles (optional, customize as needed)
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
  },
};

const DocUploader = ({onUpload}) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { showConfirm, showError, showMessage } = useContext(DialogContext);
  const [file, setFile] = useState(null); // Allow single file


  const openModal = () => setModalIsOpen(true);
  const closeModal = () => {
    setFile(null);
    setModalIsOpen(false);
  };

  const onDrop = (acceptedFiles) => {
    setFile(acceptedFiles[0]);
  };

  const handleUpload = () => {
    if (file) {
      onUpload(file);
      closeModal();
    } else {
      showError("Please select a file first.")
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: [
      'image/*',
      'application/pdf',
      '.csv',
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    multiple: false, 
  });

  return (
      <div>
          <Button
              className="ms-2"
              style={{
                  background: "#E2E1FB",
                  color: "white",
                  border: "none",
                  maxHeight: "37px",
                  backgroundColor: "rgb(214, 137, 16)"
              }} onClick={() => {
                setModalIsOpen(true)
                  
              }}>
              <FaCloudUploadAlt size={20} className="pe-1" />
              Bulk Upload
          </Button>

          <Modal
              isOpen={modalIsOpen}
              onRequestClose={closeModal}
              style={customStyles}
              contentLabel="File Upload Modal"
          >
              <h2>Upload your file</h2>
              <div
                  {...getRootProps()}
                  style={{
                      border: '2px dashed #cccccc',
                      padding: '20px',
                      textAlign: 'center',
                      cursor: 'pointer',
                      backgroundColor: isDragActive ? '#f0f8ff' : '#fff',
                  }}
              >
                  <input {...getInputProps()} />
                  {isDragActive ? (
                      <p>Drop the file here...</p>
                  ) : (
                      <p>Drag 'n' drop a file here, or click to select a file</p>
                  )}
              </div>

              {file && (
                  <div style={{ marginTop: '20px' }}>
                      <h3>Selected File:</h3>
                      <p>
                          {file.name} - {file.size} bytes
                      </p>
                  </div>
              )}

              <div style={{ marginTop: '20px' }}>
                  <Button style={{
                      background: "#E2E1FB",
                      color: "white",
                      border: "none",
                      maxHeight: "37px",
                      backgroundColor: "rgb(214, 137, 16)"
                  }} onClick={handleUpload}>Upload</Button>
                  <Button style={{
                      background: "#E2E1FB",
                      color: "white",
                      border: "none",
                      maxHeight: "37px",
                      backgroundColor: "red",
                      marginLeft: "10px"
                  }} onClick={closeModal}>
                      Cancel
                  </Button>
              </div>
          </Modal>
      </div>
  );
};

export default DocUploader;
