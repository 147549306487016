import React, { useContext, useEffect, useState } from 'react';
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import { Layout } from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import { I18nContext } from "../../store/context/i18nContext";
import AnalyticsService from "./AnalyticsService";
import { observer } from "mobx-react-lite";
import moment from 'moment';
import { typeMap } from './AnalyticsConstant';

const Analytics = ({ fromDate, tillDate }) => {
    const { t } = useContext(I18nContext);
    const { showError } = useContext(DialogContext);
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const filter = {}
            if (fromDate && tillDate) {
                filter.fromDate = moment(fromDate, 'YYYYMMDD').startOf('day').unix();
                filter.tillDate = moment(tillDate, 'YYYYMMDD').startOf('day').unix();;
            }
            await AnalyticsService.fetch(filter);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [AnalyticsService?.rows, AnalyticsService?.page, fromDate, tillDate]);

    const changePagination = () => {
        if (AnalyticsService.page != 1) {
            AnalyticsService.page = 1;
        }
    }

    return (
        <>
            <Layout
                title="Analytics"
                changePagination={changePagination}
                fetchData={fetchData}
                gridLoading={loading}
                rows={AnalyticsService.rows}
                total={AnalyticsService.totalRecords}
                page={AnalyticsService.page}
                onPageChange={async (page, rows) => {
                    await AnalyticsService.onPaginationChange(page, rows);
                }}
            >
                <Layout.TheadFull>
                    <Thead className="thead-dark">
                        <Tr>
                            <Th>{t("Consultant")}</Th>
                            <Th>{t("User")}</Th>
                            <Th>{t("Type")}</Th>
                            <Th>{t("Count")}</Th>
                            <Th>{t("Platform")}</Th>
                            <Th><div className='text-end'>{t("Keywords")}</div></Th>
                        </Tr>
                    </Thead>
                </Layout.TheadFull>

                <Layout.TbodyFull>
                    <Tbody style={{ maxHeight: "100px", overflowY: "scroll" }}>
                        {AnalyticsService.records?.map((user, index) => (
                            <Tr key={user?._id}>
                                <Td>{AnalyticsService.consultantMap[user?.consultant_id] ?? "--"}</Td>
                                <Td>{AnalyticsService.userMap[user?.user_id] ?? "--"}</Td>
                                <Td>{typeMap[user?.event_type] ?? "--"}</Td>
                                <Td>{user?.count ?? "--"}</Td>
                                <Td>{user?.platform ?? "--"}</Td>
                                <Td>{user?.keywords ?? "--"}</Td>
                            </Tr>
                        ))}
                    </Tbody>
                </Layout.TbodyFull>
            </Layout>
        </>
    );
}

export default observer(Analytics);