import { makeAutoObservable } from "mobx";
import { doGET } from "../util/httpUtil";
import { ENDPOINTS } from "../common/utils/Constants";

class RecommendationService {
    userGroups = {
        data: [],
        totalPages: 1,
        page: 1,
        rows: 10,
    };
    consultantGroups = {
        data: [],
        totalPages: 1,
        page: 1,
        rows: 10,
    };
    recommendationGroups = {
        data: [],
        totalPages: 1,
        page: 1,
        rows: 10,
    };

    constructor() {
        makeAutoObservable(this);
    }

    // Fetch User Groups
    fetchUserGroups = async ({fresh = false,fetchAll=true}) => {
        try {
            //TODO add pagination
            if (fresh) {
                this.userGroups.page = 1;
            }
            const response = await doGET(`${ENDPOINTS.grid('user-recommendation-groups')}?page=${this.userGroups.page}&rows=${fetchAll ?-1:this.userGroups.rows}`);

            this.userGroups.data = response.data.data.rows??[];
            if (this.userGroups.page == 1 && response.data.data.total) {
                this.userGroups.totalPages = Math.ceil(response.data.data.total / this.userGroups.rows);
            }
        } catch (error) {
            console.error("Error fetching user groups:", error);
        }
    };

    // Fetch Consultant Groups
    fetchConsultantGroups = async ({fresh = false,fetchAll=true}) => {
        try {
               //TODO add pagination
            if (fresh) {
                this.consultantGroups.page = 1;
            }
             const response = await doGET(`${ENDPOINTS.grid('consultant-recommendation-groups')}?page=${this.consultantGroups.page}&rows=${fetchAll ?-1:this.consultantGroups.rows}`);
            this.consultantGroups.data = response.data.data.rows??[];
            if (this.consultantGroups.page == 1 && response.data.data.total) {
                this.consultantGroups.totalPages = Math.ceil(response.data.data.total / this.consultantGroups.rows);
            }
        } catch (error) {
            console.error("Error fetching consultant groups:", error);
        }
    };
    fetchRecommendationGroups = async ({fresh = false,fetchAll=true}) => {
        try {
               //TODO add pagination
            // if (fresh) {
            //     this.recommendationGroups.page = 1;
            // }
            const response = await doGET(`${ENDPOINTS.grid('recommendation-groups')}?page=${this.recommendationGroups.page}&rows=${fetchAll ?-1:this.recommendationGroups.rows}`);
            this.recommendationGroups.data = response.data.data.rows??[];
            if (this.recommendationGroups.page == 1 && response.data.data.total) {
                this.recommendationGroups.totalPages = Math.ceil(response.data.data.total / this.recommendationGroups.rows);
            }
        } catch (error) {
            console.error("Error fetching consultant groups:", error);
        }
    };

    // Toggle User Group Page
    toggleUserGroupPage = (next = true) => {
        if (next && this.userGroups.page < this.userGroups.totalPages) {
            this.userGroups.page += 1;
        } else if (!next && this.userGroups.page > 1) {
            this.userGroups.page -= 1;
        }
        this.fetchUserGroups();
    };

    // Toggle Consultant Group Page
    toggleConsultantGroupPage = (next = true) => {
        if (next && this.consultantGroups.page < this.consultantGroups.totalPages) {
            this.consultantGroups.page += 1;
        } else if (!next && this.consultantGroups.page > 1) {
            this.consultantGroups.page -= 1;
        }
        this.fetchConsultantGroups();
    };
    toggleRecommendationGroupPage = (next = true) => {
        if (next && this.recommendationGroups.page < this.recommendationGroups.totalPages) {
            this.recommendationGroups.page += 1;
        } else if (!next && this.recommendationGroups.page > 1) {
            this.recommendationGroups.page -= 1;
        }
        this.fetchRecommendationGroups();
    };
}

const recommendationService = new RecommendationService();
export default recommendationService;
