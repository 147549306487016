import React, { useContext, useEffect, useState } from 'react'
import { CardBody } from 'reactstrap';
import DashboardService from './DashboardService';
import { DialogContext } from '../../store/context/DialogContext';
import GeneralModal from '../../components/Common/GeneralModal';

const CountsRow = ({ fromDate, tillDate }) => {
    const [totalConsultantCount, setTotalConsultantCount] = useState({});
    const [totalUserCount, setTotalUserCount] = useState({});
    const [loading, setLoading] = useState(false);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { showError } = useContext(DialogContext);

    const totalConsultants = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await DashboardService.totalConsultants();
            setTotalConsultantCount(response?.data)
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    }

    const totalUsers = async () => {
        if (loading) return;
        setLoading(true);
        try {
            const response = await DashboardService.totalUsers({ fromDate, tillDate });
            const result = response?.data?.reduce((acc, item) => {
                acc[item.type] = item.data;
                return acc;
            }, {});
            setTotalUserCount(result)
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    }

    const openModal = () => {
        setModalIsOpen(true);
    };

    useEffect(() => {
        Promise.all([
            totalConsultants(),
            totalUsers()
        ])
    }, [fromDate, tillDate])

    return (
        <>
            <CardBody>
                <div className="d-flex flex-wrap justify-content-between">
                    <div className="d-flex" style={{ cursor: 'pointer' }} onClick={openModal}>
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalConsultantCount?.totalConsultants}
                            </strong>
                            <br />
                            <h4>
                                Consultants
                            </h4>
                        </h2>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user-plus" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalUserCount?.uniqueUserCount}
                            </strong>
                            <br />
                            <h4>
                                Unique Users
                            </h4>
                        </h2>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user-plus" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalUserCount?.transactingUserCount}
                            </strong>
                            <br />
                            <h4>
                                Transacting Users
                            </h4>
                        </h2>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user-plus" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalUserCount?.uniqueTransactingUserCount}
                            </strong>
                            <br />
                            <h4>
                                Unique Transacting Users
                            </h4>
                        </h2>
                    </div>
                    <div className="d-flex">
                        <div className="p-3 col-sm-3" style={{ width: "auto", background: "#F5F5F5", borderRadius: "40px", marginBottom: "12px" }}>
                            <i className="bx bx-user-plus" style={{ fontSize: "30px", marginBottom: "0px" }} aria-hidden="true" />
                        </div>
                        <h2 className="mt-3" style={{ marginLeft: "10px" }}>
                            <strong>
                                {totalUserCount?.totalUserCount}
                            </strong>
                            <br />
                            <h4>
                                Total Users
                            </h4>
                        </h2>
                    </div>
                </div>
            </CardBody>

            <GeneralModal
                setVisible={setModalIsOpen}
                visible={modalIsOpen}
                contentStyle={{ width: "500px", }}
            >
                <h4 style={{ fontWeight: '600' }}>Category-Wise Consultant Overview (Total: {totalConsultantCount?.totalConsultants})</h4>
                <table cellPadding={5} className='mt-4' style={{ border: '1px solid grey', width: '100%' }}>
                    <thead style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                        <tr>
                            <th>Type</th>
                            <th>Count</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            totalConsultantCount?.categoryWiseConsultants?.map((e, i) => {
                                return <tr key={i} style={{ borderTop: '1px solid lightgrey' }}>
                                    {
                                        e?._id ? (
                                            <>
                                                <td>{e?._id}</td>
                                                <td>{e?.count ?? 0}</td>
                                            </>
                                        ) : null
                                    }
                                </tr>

                            })
                        }
                    </tbody>
                </table>
            </GeneralModal>
        </>
    )
}

export default CountsRow;