import React from "react";
import { Row, Col } from "reactstrap";
import CountrySelect from "../CountrySelect/CountrySelect";
import { InputField } from "../InputField/InputField";
export default function PhoneNum(props) {
  // console.log(props?.showErr, " props?.value?.phone?.length");
  return (
    <React.Fragment>
      <div className={`display-inline-block pe-0 ${props?.className}`}>
        <Row className="d-flex align-items-center">
          <Col className="col-lg-4">
            {" "}
            <CountrySelect
              value={props?.value?.countryCode}
              isd={true}
              onChange={(v) =>
                props?.onChange({ ...props?.value, countryCode: v?.value })
              }
              showErr={props?.showErr}
              required={props?.required}
              inline={props?.inline}
              borderNone={props?.borderNone}
              isDisabled={props?.isDisabled}
            />
          </Col>
          <Col className="col-lg-8">
            <InputField 
              className="w-100"
              value={props?.value?.phone}
              required={props?.required}
              onChange={(v) => {
                props?.onChange({ ...props?.value, phone: v });
              }}
              pattern="[0-9]+"
              label={props.label ? props.label : "Phone Number"}
              placeholder="Phone"
              type="tel"
              // showErr={hasErr}
              showClear={true}
              showErr={props?.showErr}
              inline={props?.inline}
              borderNone={props?.borderNone}
              RightComponent={props?.RightComponent}
              onClickRight={props?.onClickRight}
              isDisabled={props?.isDisabled}
              LeftComponent={() => {
                return phoneComponent(props);
              }}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}
function phoneComponent(props) {
  return (
    <i
      style={{ fontSize: "21px", paddingLeft: "4px", color: "#F08180" }}
      className="text-icon mdi mdi-phone"
    />
  );
}
