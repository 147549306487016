import { makeAutoObservable } from "mobx";
import { doPOST } from "../../../util/httpUtil";

class Service {
    records = [];
    totalRecords = 0;
    page = 1;
    rows = 20;
    userMap = {}

    constructor() {
        makeAutoObservable(this);
    }

    resetData = () => {
        this.page = 1;
        this.rows = 20;
        this.records = [];
        this.totalRecords = 0;
    }

    fetchUserData = async () => {
        const ids = this.records.map(e => e?.user_id);
        const resp = await doPOST("/v1/api/user/grid", {
            filter: ids?.length > 0 ? `_id=in[${ids.join(',')}]&rows=-1` : ""
        })
        this.userMap = resp.data?.data?.rows.reduce((acc, item) => {
            acc[item._id] = `${item?.fname ?? ""} ${item?.lname ?? ""}`
            return acc;
        }, {});
    }

    fetch = async (filter) => {
        const response = await doPOST(`/v1/api/dashboard/transactions?rows=${this.rows}&page=${this.page}`, {
            type: {
                userType: 2,
                type: 2,
                status: 2,
            },
            chatType: filter?.chatType ?? [],
            skills: filter?.skills ?? [],
            fromDate: filter?.fromDate,
            tillDate: filter?.tillDate
        })
        if (response.status === 200) {
            this.records = response.data?.data?.rows;
            if (this.page === 1)
                this.totalRecords = response.data?.data?.total;
            await this.fetchUserData();
        } else {
            return response;
        }
    }

    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
    };
}

const OrderService = new Service();
export default OrderService;