export const ENDPOINTS = {
    sales: (filter) => {
        let url = `/api/dashboard/sales?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    payments: (filter) => {
        let url = `/api/dashboard/revenue/stats?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    earnings: (filter) => {
        let url = `/api/dashboard/consultant/earnings?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    orderStats: (filter) => {
        let url = `/api/order/stats?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    consultantEarnings: (filter) => {
        let url = `/api/dashboard/consultant/stats?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    customerExpenditure: (filter) => {
        let url = `/api/dashboard/customer/stats?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    walletAmount: (filter) => {
        let url = `/api/dashboard/admin/wallet?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    revenue: (filter) => {
        let url = `/api/dashboard/revenue?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    orders: (filter) => {
        let url = `/api/dashboard/orders?`;
        if (filter.fromDate) url += `&fromDate=${filter.fromDate}`
        if (filter.tillDate) url += `&tillDate=${filter.tillDate}`
        return url;
    },
    totalConsultants: "/v1/api/dashboard/consultant/counts",
    totalUsers: (from, to) => `/v1/api/dashboard/user/counts?fromDate=${from}&tillDate=${to}`,
    totalConsultantEarning: (from, to) => `/v1/api/dashboard/consultant/earnings?fromDate=${from}&tillDate=${to}`,
    topups: (from, to) => `/v1/api/dashboard/top-up/stats?fromDate=${from}&tillDate=${to}`,
    consultantsById: "/v1/api/consultant/grid",
};
