import React, { useState } from 'react'
import GroupManagement from '../../components/Recommendation/Groupmanagement';
import Recommendations from '../../components/Recommendation/Recommendations';
import CreateGroupModal from '../../components/Recommendation/modals/CreateGroupModal';
import CreateRecommendationModal from '../../components/Recommendation/modals/CreateRecommendationModal';

const Recommendation = () => {
    const [activeTab, setActiveTab] = useState('groups');
    const [showCreateGroup, setShowCreateGroup] = useState(false);
    const [showCreateRecommendation, setShowCreateRecommendation] = useState(false);
  
    return (
      <div className="container-fluid p-4">
        <h2 className="mb-4">Recommendation Groups Management</h2>
        
        <ul className="nav nav-tabs mb-4">
          <li className="nav-item">
            <button 
              className={`nav-link ${activeTab === 'groups' ? 'active' : ''}`}
              onClick={() => setActiveTab('groups')}
            >
              Group Management
            </button>
          </li>
          <li className="nav-item">
            <button 
              className={`nav-link ${activeTab === 'recommendations' ? 'active' : ''}`}
              onClick={() => setActiveTab('recommendations')}
            >
              Recommendations
            </button>
          </li>
        </ul>
  
        {activeTab === 'groups' ? (
          <GroupManagement onCreateGroup={() => setShowCreateGroup(true)} />
        ) : (
          <Recommendations onCreateRecommendation={() => setShowCreateRecommendation(true)} />
        )}
  
        <CreateGroupModal 
          show={showCreateGroup}
          onHide={() => setShowCreateGroup(false)}
        />
  
        <CreateRecommendationModal
          show={showCreateRecommendation}
          onHide={() => setShowCreateRecommendation(false)}
        />
      </div>
    );
  }
  

export default Recommendation