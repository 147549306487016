import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import recommendationService from "../../../store/recommendationService";
import { doPOST } from "../../../util/httpUtil";
import { ENDPOINTS } from "../../../common/utils/Constants";
import { DialogContext } from "../../../store/context/DialogContext";

function CreateRecommendationModal({ show, onHide }) {
    const [userGroup, setUserGroup] = useState('');
    const [consultantGroup, setConsultantGroup] = useState('');
    const {userGroups,consultantGroups}=recommendationService;
    const { showError,showMessage } = useContext(DialogContext);

    const handleSubmit = async (e) => {
       try {
        
        const response=await doPOST(ENDPOINTS.create('recommendation-groups'),{
            userGroup,
            consultantGroup
        })
        if(response.status==200){
            showMessage("success", "Created SuccessFully");
            onHide(); 
            recommendationService.fetchRecommendationGroups({});
        }
        } catch (error) {
          console.log(error);
          showError(error);
       }
    };

    useEffect(()=>{
        recommendationService.fetchConsultantGroups({});
        recommendationService.fetchUserGroups({});
    },[])

 


    return (
        <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Create Recommendation</h5>
                        <button type="button" className="btn-close" onClick={onHide}></button>
                    </div>
                    <div className="modal-body">
                             <div className="mb-3">
                                <label className="form-label">User Group</label>
                                <select
                                    className="form-select"
                                    value={userGroup}
                                    onChange={e => setUserGroup(e.target.value)}
                                     
                                >
                                    <option value="">Select User Group</option>
                                    {/* Add options */}
                                    {
                                      userGroups.data.map((group,index)=>(
                                          <option value={group?._id} key={index}>{group?.name}</option> 
                                      ))
                                    }
                                </select>
                            </div>

                            <div className="mb-3">
                                <label className="form-label">Consultant Group</label>
                                <select
                                    className="form-select"
                                    value={consultantGroup}
                                    onChange={e => setConsultantGroup(e.target.value)}
                                     
                                >
                                    <option value="">Select Consultant Group</option>
                                    {/* Add options */}
                                    {
                                      consultantGroups.data.map((group,index)=>( 
                                            <option key={index} value={group?._id}>{group?.name}</option> 
                                      ))
                                    }
                                </select>
                            </div>
                     </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onHide}>
                            Cancel
                        </button>
                        <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                            Create Recommendation
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default observer(CreateRecommendationModal);