import React, { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { doGET } from '../../util/httpUtil';
import { ENDPOINTS } from './DashboardConstant';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const categoryMap = {
    "1": "Initiated",
    "2": "Successful",
    "3": "Failed",
    "10": "Manual",
}

const TopupStats = ({fromDate, tillDate}) => {
    const [inputData, setInputData] = useState([])
    
    useEffect(() => {
        const fetchData = async () => {
            try {
                const res = await doGET(ENDPOINTS.topups(fromDate, tillDate));
                setInputData(res?.data?.data)
            } catch (error) {
                console.log(error);
            }
        }

        fetchData();
    }, [fromDate, tillDate])

    const [selectedMonthIndex, setSelectedMonthIndex] = useState(0);

    const months = inputData?.map(item => item.month);

    const categories = ['1', '2', '3', '10'];

    const countData = categories?.map(cat => ({
        label: categoryMap[cat],
        data: inputData?.map(item => item?.data?.[cat]?.count || 0),
        backgroundColor: `rgba(${parseInt(cat) * 50}, 99, 132, 0.7)`,
    }));

    const chartData = {
        labels: months,
        datasets: countData,
    };

    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
            }
        },
        onClick: (_, elements) => {
            if (elements.length > 0) {
                const clickedElementIndex = elements[0].index;
                setSelectedMonthIndex(clickedElementIndex);
            }
        }
    };

    const selectedMonthData = inputData?.[selectedMonthIndex]?.data;
    const offersDetails = categories?.flatMap(cat => (
        selectedMonthData?.[cat]?.offers
            ?.filter(offer => offer.offerCode !== 'N/A')
            ?.map(offer => ({
                type: cat,
                offerCode: offer.offerCode,
                totalAmount: offer.totalAmount,
                offerAmount: offer.offerAmount,
                count: offer.count
            })) || []
    ));

    return (
        <div>
            <Bar data={chartData} options={options} />

            <h3 style={{
                fontWeight: "500",
                marginTop: 15,
            }}>Offers for {months[selectedMonthIndex]}</h3>
            {
                offersDetails.length > 0 ? (
                    <table cellPadding={5} style={{ border: '1px solid grey', width: '100%' }}>
                        <thead style={{ backgroundColor: "rgba(0, 0, 0, 0.1)" }}>
                            <tr>
                                <th>Type</th>
                                <th>Offer Code</th>
                                <th>Count</th>
                            </tr>
                        </thead>
                        <tbody>
                            {offersDetails.map((offer, idx) => (
                                <tr key={idx} style={{ borderTop: '1px solid lightgrey' }}>
                                    <td>{categoryMap[offer.type]}</td>
                                    <td>{offer.offerCode}</td>
                                    <td>{offer.count}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : <p className='pt-2 fs-6'>No offers used this month</p>
            }
        </div>
    );
};

export default TopupStats;