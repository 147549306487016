import React, { useState, useEffect } from 'react';
import GroupCard from './GroupCard';
import { observer } from 'mobx-react-lite';
import Pagination from './Pagination';
import recommendationService from '../../store/recommendationService';

function GroupManagement({ onCreateGroup }) {
    const { consultantGroups, userGroups } = recommendationService

    useEffect(() => {
        recommendationService.fetchConsultantGroups({fresh:true});
        recommendationService.fetchUserGroups({fresh:true});
    }, [])


    return (
        <div className="row">
            {/* User Groups Column */}
            <div className="col-md-6">
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">User Groups</h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => onCreateGroup('user')}
                        >
                            + Create New
                        </button>
                    </div>
                    <div className="card-body">
                        {userGroups.data.map(group => (
                            <GroupCard
                                key={group._id}
                                group={group}
                                type="user"
                              />
                        ))}

                        <Pagination type="userGroups" />

                    </div>
                </div>
            </div>

            {/* Consultant Groups Column */}
            <div className="col-md-6">
                <div className="card mb-4">
                    <div className="card-header d-flex justify-content-between align-items-center">
                        <h5 className="mb-0">Consultant Groups</h5>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => onCreateGroup('consultant')}
                        >
                            + Create New
                        </button>
                    </div>
                    <div className="card-body">
                        {consultantGroups.data.map(group => (
                            <GroupCard
                                key={group._id}
                                group={group}
                                type="consultant"
                              />
                        ))}

                        <Pagination type="consultantGroups" />

                    </div>
                </div>
            </div>
        </div>
    );
}


export default observer(GroupManagement);