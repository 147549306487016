import { makeAutoObservable } from "mobx";
import { doGET, doPOST } from "../../util/httpUtil";
import { ENDPOINTS } from "./SupportConstant";

class Service {
  records = [];
  totalRecords = 0;
  places = [];
  page = 1;
  rows = 20;
  filterOption = '';
  userMap = {}

  constructor() {
    makeAutoObservable(this);
  }

  fetchUserData = async () => {
    const ids = this.records.map(e => e?.user_id);
    const resp = await doPOST(ENDPOINTS.getCustomerGridByIds, {
      filter: ids?.length > 0 ? `_id=in[${ids.join(',')}]&rows=-1` : ""
    })
    this.userMap = resp.data?.data?.rows.reduce((acc, item) => {
      acc[item._id] = { name: `${item?.fname ?? ""} ${item?.lname ?? ""}`, phone: `${item?.countryCode ?? ""} ${item?.phone ?? ""}` };
      return acc;
    }, {});
  }

  fetch = async function (filter) {

    if (filter !== this.filterOption) {
      this.page = 1
      this.rows = 20
    }
    this.filterOption = filter

    const response = await doGET(
      ENDPOINTS.gridTicket(this.page, this.rows, filter));
    if (response.status === 200) {
      this.records = response.data.data.rows;
      this.totalRecords = response.data.data.records
      await this.fetchUserData();
    }
    return response?.data

  };

  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    await this.fetch(this.filterOption);
  };

  fetchActive = async function () {
    const response = await doGET(
      ENDPOINTS.gridActive);
    if (response.status === 200) {
      this.records = response.data.rows;
      await this.fetchUserData();
    }
    return response?.data

  };
  fetchResolved = async function () {
    const response = await doGET(
      ENDPOINTS.gridResolved);
    if (response.status === 200) {
      this.records = response.data.rows;
      await this.fetchUserData();
    }
    return response?.data

  };
  fetchChats = async function (id) {

    const response = await doGET(
      ENDPOINTS.get(id));
    return response?.data;

  };

  save = async function (data) {
    const response = await doPOST(ENDPOINTS.saveNew, data);
    this.fetch();

    console.log(response, "response");

    return response?.data?._id;
  };


  getTicketChatInfo = async (query) => {
    const response = await doGET(ENDPOINTS?.gridConversation(query))

    if (response.status === 200) {
      return response?.data
    }
  }

  addTicketChatInfo = async (data) => {
    const response = await doPOST(ENDPOINTS?.addConversation, data)
    if (response.status === 200) {
      return response?.data
    }
  }

  getTicketInfo = async (ticket_id) => {
    const response = await doGET(ENDPOINTS?.getTicketInfo(ticket_id))
    if (response.status === 200) {
      return response?.data
    }
  }

  updateTicket = async function (ticketId, data) {
    try {
      const response = await doPOST(ENDPOINTS.updateTicket(ticketId), data);
      this.fetch();
      return response;
    } catch (error) {
      console.error("Error updating ticket:", error);
      throw error;
    }
  };

  getSupportUserInfo = async (ticket_id) => {
    const response = await doGET(ENDPOINTS?.getSupportUser(ticket_id))
    if (response.status === 200) {
      return response?.data
    }
  }

  refundBalance = async (data) => {
    const response = await doPOST(ENDPOINTS.refundBalance, data)
    if (response.status === 200) {
      return true
    }
    else return false
  }

}

const SupportService = new Service();
export default SupportService;
