import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./ClientConstant";
import { doGET, doPOST } from "../../util/httpUtil";

class Service {
  records = [];
  totalRecords = 0;
  invoiceTotalRecords = 0;
  page = 1;
  rows = 20;
  invoicePage = 1;
  invoiceRows = 20;
  error = "";
  downloadFilterOption = '';
  paymentRecords = [];
  paymentTotalRecords = 0;
  paymentPage = 1;
  paymentRows = 20;
  walletBalanceMap = {};
  sessions = { chat: 0, call: 0, videoCall: 0 }

  constructor() {
    makeAutoObservable(this);
  }

  getWalletData = async () => {
    if (this.records?.length === 0) return;
    const uniqueIdsArray = [...new Set(this.records.map(item => item._id))].filter(id => id !== undefined);
    const response = await doPOST(ENDPOINTS.gridWalletByIds, { filter: uniqueIdsArray?.length > 0 ? `_id=in[${uniqueIdsArray.join(',')}]` : "" })
    this.walletBalanceMap = response.data?.data?.rows.reduce((acc, item) => {
      acc[item._id] = item.balance ?? 0;
      return acc;
    }, {});
  }

  fetch = async function (filter) {
    const response = await doGET(
      ENDPOINTS.grid(this.page, this.rows, filter)
    );
    if (response.status === 200) {
      this.records = response.data.data.rows ?? [];
      this.getWalletData();
      if (!filter?.forDownload && this.page === 1) {
        this.totalRecords = response.data.data.total;
      }
    }
    if (filter?.forDownload) {
      return response;
    } else {
      return response.data.data.rows;
    }
  };
  get = async function (id) {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data;
    }
  };

  updateWallet = async function (data, id) {
    const response = await doPOST(ENDPOINTS?.updateWallet(id), data);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  save = async (data) => {
    const response = await doPOST(ENDPOINTS.save, data);
    await this.fetch();
    console.log(response.status);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  getSpent = async (id) => {
    const response = await doGET(ENDPOINTS.getSpent(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  getOrders = async (id) => {
    const response = await doGET(ENDPOINTS.getOrders(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  getTimeSpent = async (id) => {
    const response = await doGET(ENDPOINTS.getTimeSpent(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  payment = async (id, filter) => {
    const response = await doGET(ENDPOINTS.getinvoice(this.invoicePage, this.invoiceRows, id, filter));

    if (response.status === 200 && !filter?.forDownload) {
      this.paymentRecords = response.data.rows;
      this.invoiceTotalRecords = response.data.records;
    }
    if (!filter?.forDownload) {
      return response?.data?.rows
    } else {
      return response;
    }
    // return response?.data?.rows
  };
  onPaginationChange = async (page, rows) => {
    this.page = page;
    this.rows = rows;
    this.records = []
    // this.totalRecords = 0
    await this.fetch(this.filterOption);
  };
  onPaginationChangeinvoice = async (page, rows) => {
    this.invoicePage = page;
    this.invoiceRows = rows;
    this.paymentRecords = []
    // await this.payment(this.filterOption);
  };

  edit = async function (data, id) {
    const response = await doPOST(ENDPOINTS?.editCustomer(id), data);
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  get = async (id) => {
    const response = await doGET(ENDPOINTS.get(id));
    if (response.status === 200) {
      return response.data ? response.data : response;
    }
  };

  getConversations = async (id = "") => {
    const response = await doPOST(ENDPOINTS.conversations, {
      filter: `customer_id=${id}&status=2`
    })
    if (response.status === 200) {
      this.sessions = response.data?.data?.rows?.reduce((acc, curr) => {
        if (curr.chatType === 1) acc.chat += 1;
        if (curr.chatType === 2) acc.call += 1;
        if (curr.chatType === 3) acc.videoCall += 1;
        return acc;
      }, { chat: 0, call: 0, videoCall: 0 });
    }
  }

  getConsultants = async (ids = []) => {
    const response = await doPOST(ENDPOINTS.consultants, {
      filter: ids?.length > 0 ? `_id=in[${ids.join(',')}]&rows=-1` : ""
    })
    if (response.status === 200) {
      return response.data?.data?.rows
    }
  }

  getTopConsultants = async (id = "") => {
    const response = await doPOST(ENDPOINTS.analytics, {
      filter: `user_id=${id}&event_type=PROFILE_OPEN&rows=5&sortBy=count&sortAsc=false`
    })
    if (response.status === 200) {
      const dta = response.data?.data?.rows?.map(e => e?.consultant_id)?.filter(Boolean);
      const respo = await this.getConsultants(dta)
      return respo
    }
  }

}

const ClientService = new Service();
export default ClientService;
