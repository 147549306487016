import React, { Children, useEffect, useRef, useState } from "react";
import { Table } from "react-super-responsive-table";
import { ThreeBounce } from "better-react-spinkit";
import DateSelect from "../../components/DateSelects/DateSelect"
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import "./style.css";
import {
  CardBody,
  CardTitle,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
  Button,
  Navbar,
  Label,
  Dropdown
} from "reactstrap";
import CountrySelect from "../../components/CountrySelect/CountrySelect";
import { AddButton, Breadcrumb, Pagination } from "../../components";
import DropDown from "../../components/DropDown/DropDown";
import { useCookies } from "react-cookie";
import SelectButton from "../Buttons/Icons/SelectButton";
import { useNavigate } from "react-router-dom";
import Card from 'react-bootstrap/Card';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { IoArrowBack } from "react-icons/io5";
import DateRange from "../DateSelects/DateRange"
import moment from "moment";
import DocUploader from "../DocUploader/DocUploader";

const Layout = (props) => {
  const { showViewPage = false, viewSection, showFilterLabel, hideNameInput = false, date ,changePagination} = props
  const [loading, setLoading] = useState(false);
  const [FilterBarHeight, setFilterBarHeight] = useState();
  const [cookies, setCookie] = useCookies(["user"]);
  const [drp_primary1, setDrp_primary1] = useState(false);
  const [drp_primary2, setDrp_primary2] = useState(false);
  const [drp_primary3, setDrp_primary3] = useState(false);
  const [drp_primary4, setDrp_primary4] = useState(false);
  const [drp_primary5, setDrp_primary5] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'months').format('YYYYMMDD'))
  const [tillDate, setTillDate] = useState(moment().format('YYYYMMDD'))

  const navigate = useNavigate()

  const searchEnterButtonRef = useRef(null)

  const [rowPerPage, setRowPerPage] = useState(
    cookies[`page-${props?.title}`]
  );

  let _actionMenu,
    _theadFull,
    _tbodyFull,
    _navlinks;

  Children.forEach(props?.children, (child) => {
    if (child.type === Layout.ActionMenu) {
      return (_actionMenu = child);
    }
    if (child.type === Layout.TheadFull) {
      return (_theadFull = child);
    }
    if (child.type === Layout.TbodyFull) {
      return (_tbodyFull = child);
    }
    if (child.type === Layout.NavLinks) {
      return (_navlinks = child);
    }
  });
  const { actions, addcontent, download, filterInput, emailFilter, filterOrderType, InvoiceFilter, filterInput2, showLastContact, DatePickerFrom, DatePickerTo,
    showFromToDateFilter, filterCity, filterStatus, filterApproval, filterUserType, filterCategoryType, showJoinDate, filterCountry, newFilterComponents } = props;
  const actionCount = [actions, addcontent, download, filterInput].filter(Boolean).length;

  const PaginationHeight = 90;
  const HeaderHeight = 56;
  const titleHeight = 54;
  const navbarHeight = 50;
  const FiltersHeight = (id = "LayoutFilterBar") => {
    const divElement = document?.getElementById(id);
    return divElement?.offsetHeight;
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Sample Download
    </Tooltip>
  );

  useEffect(() => {
    const layoutFilterBarHeight = FiltersHeight("LayoutFilterBar") ?? 0;
    setFilterBarHeight(layoutFilterBarHeight);
    console.log(props.hideTitleBar, props?.navbar, navbarHeight + layoutFilterBarHeight + PaginationHeight + HeaderHeight, "===========")
  }, []);
  return (
    <React.Fragment>
      <div className={`page-content px-3`} >
        <div className={`left-scroll-independent col-md-12 ms-0`} style={{ height: props?.layoutForm ? `calc(100vh - 120px)` : '', }}>
          {!props?.hideHeader?<Col id="LayoutFilterBar" className="col-12 d-flex justify-content-between  mb-3 mt-3 " style={{ postion: "relative", width: `calc(100vw - 300px)` }}>
            <div className={`d-flex flex-1`}>
              <Col className="d-flex align-items-center gap-2 flex-wrap ">
                {(filterInput && !hideNameInput) ? <Row  >
                  <Col>
                    <div className="d-flex">
                      <input className="form-control  " value={props?.search1} onKeyDown={(e) => {
                        if (e.key === 'Enter' && props?.search1) {
                          if (props)
                            props.fetchData({ isDownload: false })
                        }
                        else if (e.key === 'Enter' && !props?.search1) {
                          if (props)
                            props.fetchData({ isDownload: false })
                        }
                      }} onChange={(e) => {
                        props?.setSearch1(e.target.value)
                      }} placeholder="Search By Name" id="myInput" type="text" />
                    </div>
                  </Col>
                </Row> : ""
                }
                {(emailFilter) ? <Row  >

                  <div className="d-flex">
                    <input
                      className="form-control  "
                      value={props?.searchEmail}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && props?.searchEmail) {
                          props.fetchData()
                        }
                        else if (e.key === 'Enter' && !props?.searchEmail) {
                          if (props)
                            props.fetchData()
                        }
                      }}
                      onChange={(e) => {
                        props?.setSearchEmail(e.target.value)
                      }} placeholder="Search By Email" id="myInput" type="text" />
                  </div>

                </Row> : ""
                }
                {(filterOrderType) ? <Row >
                  <Col>
                    <DropDown
                      drp_primary={drp_primary1}
                      setDrp_primary={setDrp_primary1}
                      filterName={"OrderType"}
                      label={props?.orderType}
                      action={props.action}
                      className={"btn  bg-white"}
                      menuClassName="custom-dropdown-actionMenu"
                      borderRadius={"25px"}
                    />
                  </Col>
                </Row> : ""
                }
                {(InvoiceFilter) ? <div>

                  <div className="d-flex">
                    <input
                      className="form-control  "
                      value={props?.searchInvoiceNo} onKeyDown={(e) => {
                        if (e.key === 'Enter' && props?.searchInvoiceNo) {
                          props.fetchData({ isDownload: false })
                        }
                        else if (e.key === 'Enter' && !props?.searchInvoiceNo) {
                          if (props)
                            props.fetchData({ isDownload: false })
                        }
                      }} onChange={(e) => {
                        props?.setSearchInvoiceNo(e.target.value)
                      }} placeholder="Search  Invoice No" id="myInput" type="text" />
                  </div>

                </div> : ""
                }
                {(filterInput2 && !hideNameInput) ? <div>

                  <div className="d-flex">
                    <input
                      className="form-control  "
                      value={props?.search2} onKeyDown={(e) => {
                        if (e.key === 'Enter' && props?.search2) {
                          props.fetchData()
                        }
                        else if (e.key === 'Enter' && !props?.search2) {
                          if (props)
                            props.fetchData()
                        }
                      }} onChange={(e) => {
                        props?.setSearch2(e.target.value)
                      }} placeholder="Search By Phone" id="myInput" type="text" />
                  </div>

                </div> : ""
                }
                {(showLastContact) ? <Row className=" " style={{}}>
                  <Col>
                    <DateRange
                      placeholder={props?.lastContactDatePlaceholder}
                      allowClear={false}
                      defaultValue={[fromDate, tillDate]?.filter(date => date)?.map(date => moment(parseInt(date), 'YYYYMMDD'))}
                      // defaultValue={[null, null]}
                      disabled={[false, false]}
                      onChange={(v) => {
                        if (v === null) {
                          setFromDate(null);
                          setTillDate(null);
                        } else {
                          changePagination();
                          const formattedFromDate = moment.unix(v[0]).format('YYYYMMDD');
                          const formattedTillDate = moment.unix(v[1]).format('YYYYMMDD');
                          if (showLastContact) {
                            props?.onDateChange("lastContactDate", formattedFromDate, formattedTillDate)
                          }
                          if (showLastContact) {
                            props?.setFromDate((formattedFromDate))
                            props?.setToDate((formattedTillDate))
                          }
                        }
                      }}
                    />
                  </Col>
                </Row> : ""
                }
                {/* {DatePickerFrom ? <Row className="  px-3  " style={{ position: "relative", backgroundColor: "white", borderRadius: "25px", marginRight: "0px" }}>

                  <div className="d-flex ">
                    <Label style={{ marginRight: "10px", marginTop: "10px" }}>From : </Label>
                    <DateSelect
                      placeholder="Select Date"
                      value={props?.fromDate}
                      onChange={(v) => props?.setFromDate((v))}
                    />
                  </div>

                </Row> : ""
                }
                {DatePickerTo ? <div className=" px-3 " style={{ position: "relative", backgroundColor: "white", borderRadius: "25px", }}>
                  <div className="d-flex ">
                    <Label style={{ marginRight: "10px", marginTop: "10px" }}>To : </Label>
                    <DateSelect
                      placeholder="Select Date"
                      value={props?.toDate}
                      onChange={(v) => props?.setToDate((v))}
                    />
                  </div>

                </div> : ""
                } */}
                {(filterCity) ? <div >

                  <div className="d-flex">
                    <input
                      className="form-control  "
                      value={props?.searchCity}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' && props?.searchCity) {
                          props.fetchData()
                        }
                        else if (e.key === 'Enter' && !props?.searchCity) {
                          if (props)
                            props?.fetchData()
                        }
                      }}
                      onChange={(e) => {
                        props?.setSearchCity(e.target.value)
                      }} placeholder="Search By City" id="myInput" type="text" />

                  </div>
                </div> : ""
                }
                {(filterCountry) ? <div >
                  <CountrySelect
                    name={true}
                    // className="bg-danger"
                    placeholder="Search By Country"
                    value={props?.country}
                    onChange={(v) => props?.setCountry(v?.name)}
                  />
                </div> : ""
                }
                {(filterUserType) ? <div >

                  <DropDown
                    drp_primary={drp_primary3}
                    setDrp_primary={setDrp_primary3}
                    label={props?.userType}
                    action={props?.userTypeOptions}
                    menuClassName="custom-dropdown-actionMenu"
                    className={"btn  bg-white"}
                    borderRadius={"4px"}
                    style={{ border: "1px solid #ced4da" }}
                    filterName={"User Type"}
                  />

                </div> : ""
                }
                {(filterStatus && showFilterLabel) ?
                  <div className=" d-flex gap-2 align-items-center" >
                    <span><b>Status :</b></span>
                    <DropDown
                      drp_primary={drp_primary2}
                      setDrp_primary={setDrp_primary2}
                      label={props?.searchStatus}
                      action={props?.statusAction}
                      menuClassName="custom-dropdown-actionMenu"
                      className={"btn  bg-white "}
                      style={{ border: "1px solid #ced4da" }}
                      borderRadius={"4px"}
                      filterName={"Status"}
                    />
                  </div>
                  : (filterStatus ? <DropDown
                    drp_primary={drp_primary2}
                    setDrp_primary={setDrp_primary2}
                    label={props?.searchStatus}
                    action={props?.statusAction}
                    menuClassName="custom-dropdown-actionMenu"
                    className={"btn  bg-white "}
                    style={{ border: "1px solid #ced4da" }}
                    borderRadius={"4px"}
                    filterName={"Status"}
                  /> : null)}
                {filterApproval ?
                  <DropDown
                    drp_primary={drp_primary4}
                    setDrp_primary={setDrp_primary4}
                    label={props?.approvalStatus}
                    action={props?.approvalAction}
                    menuClassName="custom-dropdown-actionMenu"
                    className={"btn  bg-white"}
                    style={{ border: "1px solid #ced4da" }}
                    borderRadius={"4px"}
                    filterName={"Approval"}
                  />
                  : null}

                {(filterCategoryType) ? <div >
                  <DropDown
                    drp_primary={drp_primary5}
                    setDrp_primary={setDrp_primary5}
                    label={props?.categoryType}
                    action={props?.userCategoryOptions}
                    menuClassName="custom-dropdown-actionMenu"
                    className={"btn bg-white"}
                    borderRadius={"4px"}
                    style={{ border: "1px solid #ced4da" }}
                    filterName={"Category Type"}
                  />
                </div> : null
                }
                {/* <div className=" d-flex gap-2" >
                </div> */}
                {(showJoinDate || showFromToDateFilter) ? <div style={{}}>
                  <DateRange
                    placeholder={showJoinDate ? props?.joinDatePlaceholder : (showFromToDateFilter ? props?.fromToPlaceholder : "")}
                    allowClear={false}
                    defaultValue={[fromDate, tillDate]?.filter(date => date)?.map(date => moment(parseInt(date), 'YYYYMMDD'))}
                    // defaultValue={[null, null]}
                    disabled={[false, false]}
                    onChange={(v) => {
                      if (v === null) {
                        setFromDate(null);
                        setTillDate(null);
                      } else {
                        changePagination();
                        const formattedFromDate = moment.unix(v[0]).format('YYYYMMDD');
                        const formattedTillDate = moment.unix(v[1]).format('YYYYMMDD');
                        if (showJoinDate) {
                          props?.onDateChange("joinDate", formattedFromDate, formattedTillDate)
                        }
                        if (showFromToDateFilter) {
                          props?.setFromDate((formattedFromDate))
                          props?.setToDate((formattedTillDate))
                        }
                      }
                    }}
                  />

                </div> : ""
                }
                {
                  newFilterComponents ? newFilterComponents : null
                }
              </Col>
            </div>
            {actions || addcontent || download || filterInput ?
              <div className={`d-flex flex-lg-row flex-column gap-2 pt-1 justify-content-end`}>
                <div >
                  {
                    props?.filterInput ?
                      <Button ref={searchEnterButtonRef}
                        style={{
                          borderRadius: "25px",
                          backgroundColor: "#D68910",
                          border: "none",
                          padding: "4px 9px",
                          fontSize: "20px",
                        }}
                        className="uil-search "
                        id="searchButtonId"
                        onClick={()=>{
                          changePagination();
                          props?.fetchData();
                        }
                        }>

                      </Button> : null
                  }
                </div>
                
                  <div className="d-flex justify-content-end">
                    {props.actions && actionCount > 1 ?
                      <div style={{ minWidth: "95px" }}>
                        <DropDown
                          drp_primary={drp_primary1}
                          setDrp_primary={setDrp_primary1}
                          label={"Action"}
                          action={props.actions}
                          menuClassName="custom-dropdown-actionMenu"
                          className={"btn btn-outline-primary ms-2 "}
                          borderRadius={"4px"}
                        />
                      </div>
                      : ""
                    }
                    {props?.addcontent ?
                      <Button className="ms-2" style={{ width: "max-content", background: "#E2E1FB", color: "white", border: "none", backgroundColor: "rgb(214, 137, 16)", maxHeight: "37px" }} onClick={() => {
                        props?.onAddClick();
                      }}>
                        <i className="fa fa-plus" aria-hidden="true" />
                        {`  ${props?.addcontent}`}
                      </Button> : null}
                    {props?.download ?
                      <Button
                        className="ms-2"
                        style={{
                          background: "#E2E1FB",
                          color: "white",
                          border: "none",
                          maxHeight: "37px",
                          backgroundColor: "rgb(214, 137, 16)"
                        }} onClick={() => {
                          props?.fetchData && props.fetchData({ isDownload: true, fromDate, toDate: tillDate });
                        }}>
                        <FaCloudDownloadAlt size={20} className="pe-1" />
                        Download
                      </Button> : null}
                      {props?.bulkUpload &&
                    <> <DocUploader onUpload={(file) => {
                      if (file) props?.onBulkUpload(file,false)
                    }} />
                      <Button
                        className="ms-2"
                        style={{
                          background: "#E2E1FB",
                          color: "white",
                          border: "none",
                          maxHeight: "37px",
                          backgroundColor: "rgb(214, 137, 16)",
                        }}
                        onClick={() => {
                          props?.onBulkUpload(null,true)
                          // props?.fetchData && props.fetchData({ isDownload: true });
                        }}
                      >
                        <OverlayTrigger placement="top" overlay={renderTooltip}>
                          <span>
                            <FaCloudDownloadAlt size={20} />
                          </span>
                        </OverlayTrigger>
                      </Button>
                    </>
                      }
                  </div>  
              </div> : null
            }
          </Col>:null}
          <div className="layoutscroll">
            <Col className=" d-flex justify-content-between gap-3 ">
              <Card className="pt-1 flex-1" style={{ height: props?.layoutForm ? `97%` : '', marginBottom: "40px", ...props?.style }}>
                {props?.navbar && <><Navbar >
                  {_navlinks}
                </Navbar>
                  <hr style={{ marginTop: "-9px" }} />
                </>}
                {!props.hideTitleBar && (
                  <CardTitle className="pt-3 ps-3 pe-3 pb-0">
                    <div className={`d-flex justify-content-between col-12 mb-0`}>
                      <div className="d-flex justify-content-between align-items-center" >
                        {showViewPage && <div onClick={() => {
                          navigate(-1)
                        }}>
                          <IoArrowBack size={20} style={{ cursor: 'pointer' }} />
                        </div>}
                        <div className="ms-2 mt-2">
                          <Breadcrumb title={props?.title} />
                        </div>
                      </div>

                      {props?.onSelectClick && (
                        <SelectButton
                          onClick={() => {
                            props?.onSelectClick();
                          }}
                        />
                      )}
                      {props?.customOnAddClick && (
                        <UncontrolledDropdown className="ms-2">
                          <DropdownToggle
                            style={{ padding: "0", color: "transparent" }}
                          >
                            <AddButton onClick={() => { }} className={"btn btn-primary"} />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-end">
                            <DropdownItem header>Create Template</DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                props?.customOnAddClick(true);
                              }}
                            >
                              For Upload
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                props?.customOnAddClick(false);
                              }}
                            >
                              For Download
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      )}
                      {_actionMenu && (
                        <UncontrolledDropdown className="ms-2">
                          <DropdownToggle
                            color="primary"
                            style={{ padding: "0px 1px", height: 30 }}
                          >
                            <div
                              style={{
                                display: "inline-block",
                                color: "primary",
                              }}
                            >
                              <i
                                style={{ fontSize: "24px" }}
                                className="bx bx-dots-horizontal-rounded"
                              />
                            </div>
                          </DropdownToggle>
                          {_actionMenu}
                        </UncontrolledDropdown>
                      )}
                    </div>
                  </CardTitle>
                )}
                <CardBody style={{ height: props?.layoutForm ? `100%` : '', ...props?.style, maxHeight: `${props.hideTitleBar && props?.navbar ? `calc(100vh - ${navbarHeight + FilterBarHeight + PaginationHeight + HeaderHeight}px)` : (!props.hideTitleBar ? `calc(100vh - ${titleHeight + FilterBarHeight + PaginationHeight + HeaderHeight})` : `calc(100vh - ${HeaderHeight + FilterBarHeight + PaginationHeight}px)`)}`, overflow: "scroll" }}>
                  {props?.gridLoading ? (
                    <div className="d-flex align-items-center justify-content-center">
                      <ThreeBounce size={20} color="#9F73AB" />
                    </div>
                  ) : (
                    <div
                      className="table-rep-plugin table-responsive mb-0"
                      data-pattern="priority-columns"
                      style={{ height: props?.layoutForm ? `calc(100vh - 230px)` : '' }}
                    >
                      <Table striped className="mb-2 striped-table">
                        {_theadFull}
                        {_tbodyFull}
                      </Table>
                    </div>
                  )}
                </CardBody>

                {props.onPageChange && (
                  <div className="xxxxx">
                    <Pagination
                      page={props?.page}
                      rows={props?.rows}
                      total={props?.total}
                      onChange={async (page, rows) => {
                        setLoading(true);
                        await props.onPageChange(page, rows);
                        setCookie(`page-${props?.title}`, rows, { path: "/" });
                        setRowPerPage(rows);
                        setLoading(false);
                      }}
                    />
                  </div>
                )}
              </Card>
              {showViewPage ?
                viewSection : ""}
            </Col></div>
        </div >
      </div >
    </React.Fragment >
  );
};

Layout.ActionMenu = (props) => <> {props?.children} </>;
Layout.TheadFull = (props) => <> {props?.children} </>;
Layout.TbodyFull = (props) => <> {props?.children} </>;
Layout.DetailPageTitle = (props) => <> {props?.children} </>;
Layout.DetailPageBody = (props) => <> {props?.children} </>;
Layout.DetailPageFooter = (props) => <> {props?.children} </>;
Layout.NavLinks = (props) => <>{props?.children}</>

export default Layout;








