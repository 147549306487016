import { useContext, useEffect, useState } from "react";
import recommendationService from "../../store/recommendationService";
import { observer } from "mobx-react-lite";
import { doDELETE } from "../../util/httpUtil";
import { ENDPOINTS } from "../../common/utils/Constants";
import { DialogContext } from "../../store/context/DialogContext";
import RecommendationCard from "./RecommendationCard";

function Recommendations({ onCreateRecommendation }) {
    const {recommendationGroups}=recommendationService
    
  
    useEffect(() => {
      // Fetch recommendations
      recommendationService.fetchRecommendationGroups({fetchAll:true});
    }, []);


 
  
    return (
      <div>
        <div className="d-flex justify-content-end mb-4">
          <button 
            className="btn btn-primary"
            onClick={onCreateRecommendation}
          >
            Create New Recommendation
          </button>
        </div>
  
        <div className="card">
          <div className="card-header">
            <h5 className="mb-0">Existing Recommendations</h5>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th>User Group</th>
                    <th>Consultant Group</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {recommendationGroups.data.map((rec,index) => (
<RecommendationCard key={index} recommendation={rec} />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
  

  
  export default observer(Recommendations);