export const ENDPOINTS = {
    grid: `/v1/api/analytics/grid`,
    consultantsGrid: `/v1/api/consultant/grid`,
    customerGrid: `/v1/api/user/grid`,
}

export const typeMap = {
    IMPRESSION: 'Impression',
    PROFILE_OPEN: "Profile page open",
    PAYMENT_PAGE_OPEN: "Payment page open",
    TRANSACTION: "Transaction",
    SEARCH: "Keyword search",
}