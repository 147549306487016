import React, { useContext, useEffect, useState } from "react";
import Card from 'react-bootstrap/Card';
import { useParams, useNavigate } from 'react-router-dom';
import { CardBody, Col, Label, Row, Table, Button } from "reactstrap";
import ClientService from './ClientService';
import imageplaceholder from "../../assets/images/imagplaceholder.png"
import Modal from 'react-modal';
import { DialogContext } from "../../store/context/DialogContext";
import {
    InputField,
    SaveButton,
} from "../../components";
import ImageComponent from "../../components/Image/ImageComponent";
import CrossButton from '../../components/Buttons/CrossButton'
import moment from "moment";

const Tab = ({ name, val, height = 100 }) => (
    <div style={{ border: "0.1px solid #D9D9D9", height: height, padding: "12px", marginLeft: "4px", borderRadius: "6px", flex: 1 }}>
        <h5>{val}</h5>
        <p style={{ fontSize: "11px" }}>{name}</p>
    </div>
)

const ClientDetail = () => {
    const { id } = useParams()
    const [data, setData] = useState({})
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [clientBalance, setClientBalance] = useState();
    const { showError, showAlert } = useContext(DialogContext);
    const [spent, setSpent] = useState(0)
    const [orders, setOrders] = useState(0)
    const [timeSpent, setTimeSpent] = useState(0)
    const navigate = useNavigate();
    const [topConsultants, setTopConsultants] = useState([])

    const getDetails = async () => {
        try {
            let response = {};
            if (id)
                response = await ClientService.get(id)
            setData(response)
        } catch (error) {

        }
    }
    const getGender = (val) => {
        if (val == 0)
            return "Male"
        else
            return "Female"
    }

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };
    const submitForm = async () => {
        try {
            if (id) {
                await ClientService.updateWallet({ amount: clientBalance }, id);
            }
            getDetails();
            showAlert({
                title: id
                    ? "Balance has been Updated Successfully"
                    : "Balance has been Added Successfully",
                description:
                    "The details have been sent to the respective offer's Email Address",
            });
            setModalIsOpen(false);
            navigate(`/client/payment/${id}`);
        } catch (err) {
            showError(err?.message);
        }
    };

    const getSpent = async () => {
        try {
            let response = {};
            if (id)
                response = await ClientService.getSpent(id)
            setSpent(response.spent)
        } catch (error) {
        }
    }

    const getOrders = async () => {
        try {
            let response = {};
            if (id)
                response = await ClientService.getOrders(id)
            setOrders(response.orders)
        } catch (error) {
        }
    }

    const getTimeSpent = async () => {
        try {
            let response = {};
            if (id)
                response = await ClientService.getTimeSpent(id)
            setTimeSpent(response.timespent)
        } catch (error) {
        }
    }

    const getSessions = async () => {
        try {
            if (id)
                await ClientService.getConversations(id)
        } catch (error) {
            console.log(error)
        }
    }

    const getTopConsultants = async () => {
        try {
            let response = []
            if (id)
                response = await ClientService.getTopConsultants(id)
            setTopConsultants(response);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDetails();
        getSpent();
        getOrders();
        getTimeSpent();
        getSessions();
        getTopConsultants();
    }, [id])

    return (
        <>
            <Card className="mt-5 p-3" style={{ width: '26%', top: "-48px", height: "calc(100vh - 150px)", overflowY: 'scroll' }}>
                <Card.Title className=' mt-3 px-4 py-2' style={{ borderBottom: "0.1px solid black" }}>Customer Details</Card.Title>
                <div style={{ margin: "10px 0px 0px 110px", width: 100, height: 100, borderRadius: '50%', overflow: 'hidden' }}>
                    <ImageComponent source={data?.profileImage ? data?.profileImage : imageplaceholder} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                </div>
                <Card.Body>
                    <Card.Title className=" d-flex justify-content-center">{data?.fname}  {data?.lname}</Card.Title>
                    <p className=" d-flex justify-content-center">{data?.astrology ? data?.astrology : '--'}</p>

                </Card.Body>
                <div className=' d-flex justify-content-between'>
                    <Tab name={"Total Amount Spent"} val={timeSpent ? Math.round(spent * 100) / 100 : "--"} />
                    <Tab name={"Orders"} val={orders ? orders : "--"} />
                    <Tab name={"Time Spent(Mins.)"} val={timeSpent ? Math.floor(timeSpent / 60) : "--"} />
                </div>
                <div className=' d-flex justify-content-between mt-1'>
                    <Tab name={"Chats"} val={ClientService.sessions?.chat} height={70} />
                    <Tab name={"Calls"} val={ClientService.sessions?.call} height={70} />
                    <Tab name={"Video Calls"} val={ClientService.sessions?.videoCall} height={70} />
                </div>

                <Card.Title style={{ borderBottom: "0.1px solid black " }} className=" mt-3 px-1 py-2" >Details</Card.Title>
                <div>
                    <p className=" px-1">
                        Customer ID: <Label className=" px-3"> {data?._id}</Label>
                    </p>
                    <p className=" px-1">
                        Email id: <Label className=" px-3">{data?.email ? data?.email : '--'}</Label>
                    </p>
                    <p className=" px-1">
                        Phone No: <Label className=" px-3">{data?.countryCode ? "+" + data?.countryCode : "--"} {data?.phone ? data?.phone : "--"}</Label>
                    </p>
                    <p className=" px-1">
                        Gender: <Label className=" px-3">{getGender(data?.salutation) ?? ""}</Label>
                    </p>

                    <p className=" px-1">
                        Country: <Label className=" px-3">{data?.country ? data?.country : "--"}</Label>
                    </p>

                    <p className=" px-1">
                        Balance: <Label className=" px-3">{data?.balance ? data?.balance : "--"}</Label>
                    </p>
                    <p className=" px-1">
                        Last Contact: <Label className=" px-3">{data?.lastContact ? moment.unix(data?.lastContact).format('DD-MM-YYYY') : "--"}</Label>
                    </p>
                    <p className=" px-1">
                        Join Date: <Label className=" px-3">{moment.unix(data?.createdAt).format('DD-MM-YYYY')}</Label>
                    </p>
                    <p className=" px-1">
                        Total Sessions: <Label className=" px-3">{ClientService.sessions?.chat + ClientService.sessions?.call + ClientService.sessions?.videoCall}</Label>
                    </p>
                    <p className="px-1 mb-1">
                        Top Viewed Consultants:
                    </p>
                    <div className="mb-3 px-1">
                        {
                            topConsultants?.map((e) => (
                                <p key={e?._id} className="my-0 fw-bold">{e?.displayName ?? ""}</p>
                            ))
                        }
                    </div>




                    <Button style={{ right: "9px", background: "#E2E1FB", color: "white", border: "none", backgroundColor: "rgb(214, 137, 16)" }}
                        onClick={openModal}>
                        Credit Balance
                    </Button>
                    <Modal
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        contentLabel="Example Modal"
                        style={{
                            overlay: {
                                zIndex: 1000,
                            },
                            content: {
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '300px',
                                height: '180px',
                                margin: 'auto',
                                borderRadius: "16px",
                            },
                        }}
                    >
                        <div style={{ cursor: "pointer" }}>
                            <CrossButton onClick={() => { setModalIsOpen(false) }} />
                        </div>
                        <Row className="mt-3 ">
                            <Col className="col-sm-9 me-2">
                                <Label>Credit Balance</Label>
                                <Row>
                                    <InputField
                                        className="col-sm-11"
                                        required={true}
                                        placeholder="Credit Balance"
                                        value={clientBalance}
                                        onChange={(v) => setClientBalance(v)}
                                        maxlength={3}
                                    />
                                </Row>
                            </Col>
                            <Col className="col-sm-2">
                                <SaveButton
                                    style={{ top: '1px', right: "-25px" }}
                                    onClick={submitForm}
                                    label={"Add"}
                                />
                            </Col>
                        </Row>
                        {/* <Button style={{
                            right: "9px", background: "#E2E1FB", color: "black", border: "none",
                            backgroundColor: "rgb(214, 137, 16)"
                        }}
                            onClick={() => { setModalIsOpen(false) }}>Close</Button> */}

                    </Modal>
                </div>

            </Card ></>
    )
}

export default ClientDetail