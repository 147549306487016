import { observer } from 'mobx-react-lite'
import React from 'react'
import recommendationService from '../../store/recommendationService'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6'

const Pagination = ({ type = "userGroups" }) => {
    const {userGroups,consultantGroups}=recommendationService

    const handleNextClick = () => {
        if (type == 'userGroups') {
            return recommendationService.toggleUserGroupPage(true);
        }
        return recommendationService.toggleConsultantGroupPage(true);
    }
    const handlePrevClick = () => {
        if (type == 'userGroups') {
            return recommendationService.toggleUserGroupPage(false);
        }
        return recommendationService.toggleConsultantGroupPage(false);
    }

    const isPrevDisabled=()=>type=='userGroups' && userGroups.page==1 || type=="consultantGroups" && consultantGroups.page==1;
    const isNextDisabled=()=>type=='userGroups' && userGroups.page==userGroups.totalPages || type=="consultantGroups" && consultantGroups.page==consultantGroups.totalPages;

    const currentPage=type=="userGroups"?userGroups?.page:consultantGroups?.page;
    const totalPages=type=="userGroups"?userGroups?.totalPages:consultantGroups?.totalPages;

    return (
        <>
            <div className="pagination align-items-center gap-2 float-end">
                <button disabled={isPrevDisabled()} className=" btn btn-primary btn-sm" onClick={handlePrevClick}  >
                    <FaChevronLeft/>
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button disabled={isNextDisabled()} className=" btn btn-primary btn-sm" onClick={handleNextClick}  >
                    <FaChevronRight/>
                </button>
            </div>
        </>
    )
}

export default observer(Pagination);