import { makeAutoObservable } from "mobx";
import { ENDPOINTS } from "./AnalyticsConstant";
import { doGET, doPOST } from "../../util/httpUtil";

class Service {
    records = [];
    totalRecords = 0;
    page = 1;
    rows = 20;
    error = "";
    userMap = {};
    consultantMap = {};

    constructor() {
        makeAutoObservable(this);
    }

    fetchUserData = async () => {
        const ids = this.records.map(e => e?.user_id).filter(Boolean);;
        const resp = await doPOST(ENDPOINTS.customerGrid, {
            filter: ids?.length > 0 ? `_id=in[${ids.join(',')}]&rows=-1` : ""
        })
        this.userMap = resp.data?.data?.rows.reduce((acc, item) => {
            acc[item._id] = `${item?.fname ?? ""} ${item?.lname ?? ""}`
            return acc;
        }, {});
    }

    fetchConsultantData = async () => {
        const ids = this.records.map(e => e?.consultant_id).filter(Boolean);;
        const resp = await doPOST(ENDPOINTS.consultantsGrid, {
            filter: ids?.length > 0 ? `_id=in[${ids.join(',')}]&rows=-1` : ""
        })
        this.consultantMap = resp.data?.data?.rows.reduce((acc, item) => {
            acc[item._id] = `${item?.fname ?? ""} ${item?.lname ?? ""}`
            return acc;
        }, {});
    }

    fetch = async (filter = null) => {
        let url = `rows=${this.rows}&page=${this.page}`;
        if (filter?.fromDate && filter?.tillDate) {
            url += `&updatedAt=btw[${filter?.fromDate},${filter?.tillDate}]`
        }
        const response = await doPOST(ENDPOINTS.grid, { filter: url });
        if (response.status === 200) {
            this.records = response.data?.data?.rows;
            if (this.page === 1)
                this.totalRecords = response.data?.data?.total;

            await Promise.all([
                this.fetchConsultantData(),
                this.fetchUserData()
            ])
        }
        if (!filter?.forDownload) {
            return response?.data
        } else {
            return response;
        }
    };

    onPaginationChange = async (page, rows) => {
        this.page = page;
        this.rows = rows;
        // await this.fetch(this.filterOption);
    };
}

const AnalyticsService = new Service();
export default AnalyticsService;