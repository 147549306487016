import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { CrossButton } from '../../components';
import { doGET } from '../../util/httpUtil';
import { ENDPOINTS } from './ConsultantConstant';
import { ThreeBounce } from 'better-react-spinkit';
import { IoMdCloudDownload } from "react-icons/io";

const SummaryModal = ({ modalIsOpen, setModalIsOpen, id }) => {
    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const closeModal = () => {
        setModalIsOpen({
            visible: false,
            id: ""
        });
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true)
                const res = await doGET(ENDPOINTS.summary(id));
                setData(res.data?.data[0]);
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false);
            }
        }
        setData({})
        if (!!id)
            fetchData();
    }, [id])

    function formatTime(seconds) {
        if(seconds === 0) return "0 sec";
        if(!seconds) return "--"
        if (seconds < 60) {
            return `${seconds?.toFixed(2)} sec`;
        } else if (seconds < 3600) {
            const minutes = Math.floor(seconds / 60).toFixed(2);
            return `${minutes} min`;
        } else {
            const hours = Math.floor(seconds / 3600).toFixed(2);
            return `${hours} hr`;
        }
    }
    const downloadSummaryExcel = async (id) => {
        try {
            setLoading(true);
            const response = await doGET(ENDPOINTS.summary(id, "&downloadExcel=true"));
            const csvContent = 'data:text/csv;charset=utf-8,' + encodeURIComponent(response?.data?.data);
            const link = document.createElement('a');
            link.href = csvContent;
            link.target = '_blank';
            link.download = `consultant_summary_${id}.csv`;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
    
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };
    

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            contentLabel="Modal"
            style={{
                overlay: {
                    zIndex: 1000,
                    backgroundColor: 'rgba(0, 0, 0, 0.75)'
                },
                content: {
                    margin: 'auto',
                    width: 700,
                    borderRadius: "16px",
                    height:500
                },
            }}
        >
            <div style={{ cursor: "pointer" }}>
                <CrossButton onClick={closeModal} style={{top:16}} />
                <IoMdCloudDownload size={26} style={{float:"right",position:"relative", right:20,bottom:5}} onClick={()=>{downloadSummaryExcel(id)}} />
            </div>
            <h4 style={{ fontWeight: '600', fontSize:"18px" }}>Consultant Summary</h4>
            {
                loading ? <ThreeBounce /> : (
                    <table  cellPadding={5} className='mt-4 table table-striped' style={{ border: '0px solid grey',borderRadius:"15px", width: '100%' }}>
                        <thead >
                            <tr>
                                <th style={{background:"#d8dadc"}}>Metric</th>
                                <th style={{background:"#d8dadc"}}>Value</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              
                                <td className='p-2'>Chat Transactions</td>
                                <td>{data?.chatTransactions ?? 0}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Voice Call Transactions</td>
                                <td>{data?.voiceTransactions ?? 0}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Video Call Transactions</td>
                                <td>{data?.videoTransactions ?? 0}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Total Transactions</td>
                                <td>{data?.totalTransactions ?? 0}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Missed Call Percentage</td>
                                <td>{data?.missedCallPercentage ?? "--"}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Average Call Duration</td>
                                <td>{formatTime(data?.avgCallDuration) ?? 0}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Unique Users</td>
                                <td>{data?.uniqueUsers ?? 0}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Repeating Users</td>
                                <td>{data?.repeatUsers ?? 0}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Total Online Duration</td>
                                <td>{formatTime(data?.totalOnlineDuration ?? 0)}</td>
                            </tr>
                            <tr >
                                <td className='p-2'>Average Response Time</td>
                                <td>{formatTime(data?.avgResponseTime ?? 0)}</td>
                            </tr>
                        </tbody>
                    </table>
                )
            }
        </Modal>
    )
}

export default SummaryModal;