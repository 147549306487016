import React, { useContext, useState } from "react";
import { Button, Card, Col, Row } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'
import moment from "moment";
import SupportService from "./SupportService";
import GeneralModal from "../../components/Common/GeneralModal";
import { InputField, SaveButton } from "../../components";
import { DialogContext } from '../../store/context/DialogContext';

const SupportCard = (props) => {

    const { ele } = props
    const navigate = useNavigate()
    const [visible, setVisible] = useState({ visible: false, id: "" })
    const [loading, setLoading] = useState(false)
    const [popupData, setPopupData] = useState({})
    const { showMessage, showError } = useContext(DialogContext)

    const getDate = (val) => {
        const date = new Date(val);
        const options = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        };

        const formattedDate = date.toLocaleString('en-US', options);
        return formattedDate
    }
    const getStatus = (val) => {
        if (val == 1)
            return "Open";
        if (val == 2)
            return "Closed"
    }

    const onRefundBtnClick = (event) => {
        event.stopPropagation()
        setVisible({ visible: true, id: ele?._id })
    }

    const refundBalace = async () => {
        if (loading) return;
        if (!popupData?.amount || popupData?.amount === "0" ||  popupData?.amount <= 0) {
            showMessage("error", "Enter a valid amount");
            return
        }
        try {
            setLoading(true)
            const res = await SupportService.refundBalance({
                amount: popupData?.amount,
                notes: popupData?.notes,
                user_id: ele?.user_id
            })
            if (res) {
                showMessage("success", "Refund successful");
                setVisible({ visible: false, id: "" })
                setPopupData({})
            }
            else showError("Something went wrong");
        } catch (error) {
            showError(error)
        } finally {
            setLoading(false)
        }
    }


    return <Card
        className='col-sm-12 box-shadow'
        style={{ width: "100%", height: "170px", fontSize: "16px" }}
    >
        <Row className='px-2'>
            <Col>
                <h2>
                    <strong style={{ color: "black" }}>
                        Ticket ID : <span style={{ color: "rgba(26, 177, 31, 1)" }}>{ele?.ticketNo} </span>
                    </strong>
                </h2>
            </Col>
            <Col style={{ textAlign: "end" }}>
                {SupportService.userMap?.[ele?.user_id]?.name}
            </Col>
        </Row>
        <Row className='mt-1 pe-2' >
            <Col>
                <h2><strong className='p-2'>{ele?.heading}</strong></h2>
            </Col>
            <Col style={{ textAlign: "end" }}>
                {SupportService.userMap?.[ele?.user_id]?.phone}
            </Col>
        </Row>
        <Row className='mt-1 pe-2'>
            <Col>
                <h4 className='p-2'>{ele?.desc}</h4>
            </Col>
            <Col style={{ textAlign: "end", color: "black", fontWeight: "400" }}>
                Status : <span style={{ color: "#756464" }}>{getStatus(ele?.status)}</span>
            </Col>
        </Row>
        <Row>
            <Col className="d-flex">
                <Button type="button" onClick={onRefundBtnClick} className="p-1 ms-2 border-0 bg-dark" style={{ fontSize: 12 }}>
                    Refund
                </Button>
                <Button
                    type="button"
                    onClick={() => navigate(`/supportdetail/${ele?.ticketNo}/${ele?._id}`, { state: { ticketNo: ele?.ticketNo } })}
                    className="p-1 ms-2 border-0 bg-primary"
                    style={{ fontSize: 12 }}
                >
                    View Details
                </Button>
            </Col>
            <Col style={{ textAlign: "end", fontWeight: 400 }}>
                {moment.unix(ele?.createdAt).format('lll')}
            </Col>
        </Row>

        <GeneralModal
            visible={visible.visible}
            setVisible={(val) => {
                setVisible({ visible: val, id: "" })
            }}
            contentStyle={{
                width: 500,
                height: 300,
            }}
        >
            <InputField
                placeholder="Amount"
                className="mt-4"
                value={popupData?.amount}
                onChange={(v) => {
                    setPopupData({ ...popupData, amount: v })
                }}
            />
            <textarea
                value={popupData?.notes}
                onChange={(v) => {
                    setPopupData({ ...popupData, notes: v.target.value });
                }}
                placeholder="Description"
                rows={5}
                className="mt-3 w-100 p-2"
                style={{ border: "1px solid #ced4da", outline: "none" }}
            />
            <SaveButton
                style={{ right: "0%" }}
                onClick={refundBalace}
                label={"Refund"}
            />
        </GeneralModal>
    </Card>
};

export default SupportCard;