import { useContext, useState } from "react";
import { DialogContext } from "../../store/context/DialogContext";
import { doDELETE } from "../../util/httpUtil";
import recommendationService from "../../store/recommendationService";
import { ENDPOINTS } from "../../common/utils/Constants";
import CreateGroupModal from "./modals/CreateGroupModal";

function GroupCard({ group, type }) {

    const { showError,showMessage } = useContext(DialogContext);
    const [visible,setVisible]=useState(false);
 

    const onRemove=async(id)=>{
        try {
            const response=await doDELETE(ENDPOINTS.delete(`${type}-recommendation-groups`,id));
            if(response.status==200){
                showMessage("success", "Deleted SuccessFully");
                if(type=='user'){
                    recommendationService.fetchUserGroups({});
                }else{
                    recommendationService.fetchConsultantGroups({})
                }
            }
        } catch (error) {
            showError(error);
        }
    }

    return (
      <div className="card mb-3">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <h6 className="card-title mb-0">{group.name}</h6>
            <div>
              <button 
                className="btn btn-sm btn-outline-primary me-2"
                onClick={() => setVisible(true)}
              >
                Edit
              </button>
              <button 
                className="btn btn-sm btn-outline-danger"
                onClick={() => onRemove(group?._id)}
              >
                Delete
              </button>
            </div>
          </div>
          {group.description && (
            <p className="card-text small mb-1">{group.description}</p>
          )}
          <p className="card-text small text-muted">
            {type === 'user' ? 'Users' : 'Consultants'}: {group.ids?.length || 0}
          </p>
        </div>

        <CreateGroupModal
          show={visible}
          onHide={() => setVisible(false)}
          data={group}
          type={type}
        />
      </div>
    );
  }

  export default GroupCard;