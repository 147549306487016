import { observer } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Card, CardBody, Col, Label, Row, Table } from "reactstrap";
import {
  DateSelect,
  InputField,
  SaveButton,
  SelectField,
} from "../../components";
import { DialogContext } from "../../store/context/DialogContext";
import OfferService from "./OfferService";
import Checkbox from "./Checkbox";


const EditOffer = (props) => {
  const [data, setData] = useState();
  const navigate = useNavigate()
  const { id } = useParams();
  const { showError, showAlert } =
    useContext(DialogContext);

  const getDetails = async () => {
    try {
      let response = {};
      if (id) response = await OfferService.get(id);

      setData(response?.data);
    } catch (error) { }
  };

  useEffect(() => {
    getDetails();
  }, [id]);

  const submitForm = async () => {
    try {
      let response;
      if (id) {
        response = await OfferService.edit(data, id);
      } else {
        response = await OfferService.save(data);
      }
      console.log(response?.data);
      getDetails();
      showAlert({
        title: id
          ? " Offer has been Updated Successfully"
          : "New Offer has been Created Successfully",
        description:
          "The details have been sent to the respective offer's Email Address",
      });
      navigate(`/offer`);
    } catch (err) {
      showError(err?.message);
    }
  };
  const kind = [
    { value: "discount", label: "discount" },
    { value: "recharge", label: "recharge" },
  ];

  return (
    <React.Fragment>
      <Card
        className="mt-3"
        style={{
          width: "auto",
          height: "calc(100vh - 100px)",
          overflowY: "scroll",
          marginRight: 20,
          marginLeft: 20,
        }}
      >
        <CardBody
          className="py-3 px-5"
          style={{ top: "10%", position: "relative", width: "88%" }}
        >
          <Row className="mt-3">
            <Col className="col-sm-4">
              <Label>Offer Name</Label>
              <Row>
                <InputField
                  className="col-sm-12"
                  required={true}
                  placeholder="Name"
                  value={data?.name}
                  onChange={(v) => setData({ ...data, name: v })}
                  maxlength={3}
                />
              </Row>
            </Col>
            <Col className="col-sm-4">
              <Label>Amount</Label>
              <Row>
                <InputField
                  className="col-sm-12"
                  required={true}
                  placeholder="Amount"
                  value={data?.amount}
                  onChange={(v) => setData({ ...data, amount: v })}
                  type="number"
                />
              </Row>
            </Col>
            <Col className="col-sm-4 ">
              <Label>Kind </Label>
              <Row>
                <SelectField
                  className="col-sm-12"
                  data={kind}
                  placeholder="Kind"
                  value={data?.kind}
                  onChange={(v) =>
                    setData({ ...data, kind: v.value })
                  }
                />
              </Row>
            </Col>
            <Col className="col-sm-3 mt-5 ">
              <Label>Expiry</Label>
              <Row className="col-sm-12">
                <DateSelect
                  placeholder="Select Date"
                  value={data?.expiry}
                  onChange={(v) => setData({ ...data, expiry: v })}
                />
              </Row>
            </Col>
            <Col className="col-sm-3 mt-5">
              <div className="d-flex mt-2">
                <Label className="mt-4">Multi Use</Label>
                <div className="ms-4 mt-3">
                  <Checkbox
                    checked={data?.multiUse}
                    onClick={(v) =>
                      setData({
                        ...data,
                        multiUse: !data?.multiUse,
                      })
                    }
                  />
                </div>
              </div>
            </Col>
            <Col className="col-sm-3 mt-5">
              <div className="d-flex mt-2">
                <Label className="mt-4">Percentage</Label>
                <div className="ms-4 mt-3">
                  <Checkbox
                    checked={!data?.isAbs}
                    onClick={(v) =>
                      setData({
                        ...data,
                        isAbs: !data?.isAbs,
                      })
                    }
                  />
                </div>
              </div>
            </Col>
            <Col className="col-sm-3 mt-5">
              <div className="d-flex mt-2">
                <Label className="mt-4">Active</Label>
                <div className="ms-4 mt-3">
                  <Checkbox
                    checked={data?.active}
                    onClick={(v) =>
                      setData({
                        ...data,
                        active: !data?.active,
                      })
                    }
                  />
                </div>
              </div>
            </Col>
            <Col className="col-sm-3 mt-5"> 
                <Label className="">Min Amount</Label>
                <InputField
                  className="col-sm-12"
                  placeholder="Min Amount"
                  value={data?.minAmount}
                  onChange={(v) => setData({ ...data, minAmount: v })}
                  type="number"
                />
            </Col>


            {data?.kind == "recharge" ? <Col className="col-sm-3 mt-5">
              <div className="d-flex align-items-center row mt-2">
                <div className="col-10 text-end">Instant Recharge</div>
                <div className=" col-2 ">
                  <Checkbox
                    checked={data?.subKind == "instantrecharge"}
                    disabled={true}
                    onClick={(v) =>
                      setData({
                        ...data,
                        subKind: data?.subKind != "instantrecharge" ? "instantrecharge" : "",
                      })
                    }
                  />
                </div>
              </div>
            </Col> : null}



            <Col className="col-sm-12 mt-4">
              <Label>Notes</Label>
              <Row className="mx-2">
                <textarea
                  className="col-sm-12"

                  value={data?.notes}
                  onChange={(v) => {
                    console.log(v.target.value);
                    setData({ ...data, notes: v.target.value });
                  }}
                  rows={3}
                  cols={160}
                  style={{ border: "1px solid #ced4da", outline: "none" }}
                />
              </Row>
            </Col>
          </Row>

          <div class="offer mt-4">
            <h3 className="fw-bold">How It Works:</h3>
            <h4>Recharge:</h4>
            <p>Add funds to your wallet:</p>
            <ul>
              <li><strong>Percentage:</strong> Get a percentage credited (e.g., for AED 100, receive 10% more i.e. AED 110 credit).</li>
              <li><strong>Absolute:</strong> Receive an additional fixed amount credited (e.g., add $50, get an extra $10 credit).</li>
            </ul>

            <h4>Discount:</h4>
            <p>Save on transactions:</p>
            <ul>
              <li><strong>Percentage:</strong> A percentage off your total purchase (e.g., 15% off a AED 100 purchase = AED 15 savings).</li>
              <li><strong>Absolute:</strong> A fixed discount will be applied if the amount exceeds the minimum required amount for the offer.</li>
            </ul>
            {data?.multiUse ? <p>This offer can be used multiple times, maximizing your savings!</p> : null}
            <p><strong>Minimum Amount:</strong> <span id="min-amount">This is the minimum transaction amount required to avail the offer.</span></p>
          </div>

          <Row className="mt-3 d-flex justify-content-end">
            <SaveButton
              style={{ right: "0%" }}
              onClick={submitForm}
              label={data?._id ? "Update" : "Save"}
              disabled={data ? false : true}
            />
          </Row>
        </CardBody>
      </Card>
    </React.Fragment >
  );
};

export default observer(EditOffer);
