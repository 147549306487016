export const API_METHODS = {
  GET: "get",
  POST: "post",
  PUT: "put",
  PATCH: "patch",
  DELETE: "delete",
};

export const ENDPOINTS = {
  login: "/auth/customer/authorize",
  adminLogin: "/api/auth/admin/login",
  adminOTP: "/api/auth/admin/otp",
  logout: "/api/profile/logout",
  getCaptcha: '/api/auth/get/captcha',
  validateCaptcha: '/api/auth/verify/captcha',
  grid: (module) => `/v1/api/${module}/grid`,
  create: (module) => `/v1/api/${module}/create`,
  update: (module) => `/v1/api/${module}/update`,
  delete: (module, id) => `/v1/api/${module}/${id}/delete`,
}
