import { observer } from 'mobx-react-lite'
import React, { useContext } from 'react'
import { doDELETE } from '../../util/httpUtil';
import { ENDPOINTS } from '../../common/utils/Constants';
import recommendationService from '../../store/recommendationService';
import { DialogContext } from '../../store/context/DialogContext';

const RecommendationCard = ({ recommendation }) => {
    const { userGroups, consultantGroups } = recommendationService;
    const { showError, showMessage } = useContext(DialogContext);

    const onRemove = async (id) => {
        try {
            const response = await doDELETE(ENDPOINTS.delete('recommendation-groups', id));
            if (response.status == 200) {
                showMessage("success", "Deleted SuccessFully");
                recommendationService.fetchRecommendationGroups({});
            }
        } catch (error) {
            showError(error);
        }
    }

    const getUserGroup = () => {
        return userGroups.data.find(group => group?._id == recommendation?.userGroup)?.name ?? "User Group"
    }
    const getConsultantGroup = () => {
        return consultantGroups.data.find(group => group?._id == recommendation?.consultantGroup)?.name ?? "Consultant Group"
    }

    return (
        <tr >
            <td>
                {getUserGroup()}
                <br />

            </td>
            <td>
                {getConsultantGroup()}
                <br />

            </td>
            <td>
                <button
                    className="btn btn-sm btn-outline-danger"
                    onClick={() => onRemove(recommendation?._id)}
                >
                    Remove
                </button>
            </td>
        </tr>
    )
    // return (
    //     <tr key={rec._id}>
    //         <td>
    //             {recommendation.userGroup}
    //             <br />

    //         </td>
    //         <td>
    //             {recommendation?.consultantGroup}
    //             <br />

    //         </td>
    //         <td>
    //             <button
    //                 className="btn btn-sm btn-outline-danger"
    //                 onClick={() => onRemove(rec?._id)}
    //             >
    //                 Remove
    //             </button>
    //         </td>
    //     </tr>
    // )
}

export default observer(RecommendationCard)