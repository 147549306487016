import React, { useContext, useEffect, useState } from "react";
import { I18nContext } from "../../../store/context/i18nContext";
import { DialogContext } from "../../../store/context/DialogContext";
import OrderService from "./OrderService.js";
import { Layout, yyyymmddtoTimeStamp } from "../../../components/index.js";
import { Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import GeneralModal from "../../../components/Common/GeneralModal.js";
import { observer } from "mobx-react-lite";
import Checkbox from "../../Consultant/Checkbox.js";
import { UserContext } from "../../../store/context/userContext.js";

const OrderGrid = ({ visible, setVisible, fromDate, tillDate }) => {
    const { t } = useContext(I18nContext);
    const { skills } = useContext(UserContext);
    const { showError } = useContext(DialogContext);
    const [filter, setFilter] = useState({
        skill: {
            label: "All Skills",
            value: "",
        }
    });
    const [loading, setLoading] = useState(false);

    const fetchData = async () => {
        if (loading) return;
        setLoading(true);
        try {
            let filterObj = {};
            if (fromDate && tillDate) {
                filterObj.fromDate = yyyymmddtoTimeStamp(fromDate);
                filterObj.tillDate = yyyymmddtoTimeStamp(tillDate);
            }
            if (filter.skill.value) {
                filterObj.skills = [filter.skill.value];
            }
            let chatType = [];
            if (filter?.chat) {
                chatType.push(1);
            }
            if (filter?.call) {
                chatType.push(2);
            }
            if (filter?.videoCall) {
                chatType.push(3);
            }
            if (chatType.length !== 0) {
                filterObj.chatType = chatType;
            }
            await OrderService.fetch(filterObj);
        } catch (e) {
            showError(e);
        }
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [OrderService.page, OrderService.rows, fromDate, tillDate, filter]);

    const changePagination = () => {
        if (OrderService.page != 1) {
            OrderService.page = 1;
        }
    }

    const userTypeOptions = [
        {
            label: "All Skills",
            value: "",
            callback: (e) => {
                changePagination();
                setFilter({
                    ...filter,
                    skill: {
                        label: "All Skills",
                        value: "",
                    }
                })
            },
        },
        ...skills?.map(e => ({
            label: e?.name,
            value: e?.name,
            callback: (e) => {
                changePagination();
                setFilter({
                    ...filter,
                    skill: {
                        label: e,
                        value: e,
                    }
                })
            },
        })),
    ]

    return (
        <>
            <GeneralModal
                visible={visible}
                setVisible={setVisible}
                contentStyle={{
                    height: "fit-content",
                    minHeight: "75%",
                }}
            >
                <Layout
                    insidePane
                    changePagination={changePagination}
                    hideTitleBar={true}
                    disableMultiple={"Disable"}
                    gridLoading={loading}
                    rows={OrderService.rows}
                    total={OrderService.totalRecords}
                    page={OrderService.page}
                    onPageChange={async (page, rows) => {
                        await OrderService.onPaginationChange(page, rows);
                    }}
                    // filterUserType
                    // userType={filter?.skill?.label}
                    // userTypeOptions={userTypeOptions}
                    // newFilterComponents={(
                    //     <div className="d-flex align-items-center">
                    //         <Checkbox
                    //             checked={filter?.chat}
                    //             label="Chat"
                    //             onClick={(v) => setFilter({ ...filter, chat: v.target.checked })}
                    //         />
                    //         <Checkbox
                    //             checked={filter?.call}
                    //             label="Audio Call"
                    //             onClick={(v) => setFilter({ ...filter, call: v.target.checked })}
                    //         />
                    //         <Checkbox
                    //             checked={filter?.videoCall}
                    //             label="Video Call"
                    //             onClick={(v) => setFilter({ ...filter, videoCall: v.target.checked })}
                    //         />
                    //     </div>
                    // )}
                >
                    <Layout.TheadFull>
                        <Thead className="thead-dark">
                            <Tr>
                                <Th>{t("User")}</Th>
                                <Th>{t("Transaction Type")}</Th>
                                <Th>{t("Coupon")}</Th>
                                <Th>{t("Discount Amount")}</Th>
                                <Th>{t("Total Amount")}</Th>
                                <Th><div style={{ textAlign: 'end' }}>{t("Amount After Discount")}</div></Th>
                            </Tr>
                        </Thead>
                    </Layout.TheadFull>

                    <Layout.TbodyFull>
                        <Tbody style={{ maxHeight: "100px", overflowY: "scroll" }}>
                            {OrderService?.records?.map((user, index) => (
                                <Tr key={index}>
                                    <Td>{OrderService.userMap?.[user?.user_id]}</Td>
                                    <Td>{user?.type === 1 ? "Credit" : "Debit"}</Td>
                                    <Td>{user?.offerCode ?? "--"}</Td>
                                    <Td>{user?.offerAmount ?? 0}</Td>
                                    <Td>{user?.amount}</Td>
                                    <Td>{user?.amount - (user?.offerAmount ?? 0)}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Layout.TbodyFull>
                </Layout>
            </GeneralModal>
        </>
    );
};

export default observer(OrderGrid);