// import { useState } from "react";
// import Clients from "../../../pages/Clients/Clients";

// function CreateGroupModal({ show, onHide }) {
//     const [groupType, setGroupType] = useState('user');
//     const [name, setName] = useState('');
//     const [description, setDescription] = useState('');
//     const [selectedMembers, setSelectedMembers] = useState([]);
  
//     const handleSubmit = async (e) => {
//       e.preventDefault();
//       // Implement group creation
//     };
  
//     return (
//       <div className={`modal ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }}>
//         <div className="modal-dialog">
//           <div className="modal-content">
//             <div className="modal-header">
//               <h5 className="modal-title">Create New Group</h5>
//               <button type="button" className="btn-close" onClick={onHide}></button>
//             </div>
//             <div className="modal-body">
//               <form onSubmit={handleSubmit}>
//                 <div className="mb-3">
//                   <label className="form-label">Group Type</label>
//                   <div>
//                     <div className="form-check form-check-inline">
//                       <input
//                         type="radio"
//                         className="form-check-input"
//                         value="user"
//                         checked={groupType === 'user'}
//                         onChange={e => setGroupType(e.target.value)}
//                       />
//                       <label className="form-check-label">User Group</label>
//                     </div>
//                     <div className="form-check form-check-inline">
//                       <input
//                         type="radio"
//                         className="form-check-input"
//                         value="consultant"
//                         checked={groupType === 'consultant'}
//                         onChange={e => setGroupType(e.target.value)}
//                       />
//                       <label className="form-check-label">Consultant Group</label>
//                     </div>
//                   </div>
//                 </div>
  
//                 <div className="mb-3">
//                   <label className="form-label">Name</label>
//                   <input
//                     type="text"
//                     className="form-control"
//                     value={name}
//                     onChange={e => setName(e.target.value)}
//                     required
//                   />
//                 </div>
  
//                 <div className="mb-3">
//                   <label className="form-label">Description</label>
//                   <textarea
//                     className="form-control"
//                     value={description}
//                     onChange={e => setDescription(e.target.value)}
//                   />
//                 </div>
  
//                 {/* Member selection component here */}
//                 <Clients insidePane={true}/>
//               </form>
//             </div>
//             <div className="modal-footer">
//               <button type="button" className="btn btn-secondary" onClick={onHide}>
//                 Cancel
//               </button>
//               <button type="submit" className="btn btn-primary">
//                 Create Group
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     );
//   }

  
//   export default CreateGroupModal;



import { useContext, useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Form, FormGroup, Label, Input, Row, Col } from 'reactstrap';
import Clients from "../../../pages/Clients/Clients";
import { doPATCH, doPOST, doPUT } from "../../../util/httpUtil";
import { ENDPOINTS } from "../../../common/utils/Constants";
import { DialogContext } from "../../../store/context/DialogContext";
import recommendationService from "../../../store/recommendationService";
import Consultant from "../../../pages/Consultant/Consultant";

function CreateGroupModal({ show, onHide,data=null,type }) {
    const { showError,showMessage } = useContext(DialogContext);
    const [groupType, setGroupType] = useState(type??'user');
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [selected,setSelected]=useState([]);

    const onSelectionChange=(user_id)=>{
      setSelected(prev=>{
        const indexFound=prev.findIndex(id=>id==user_id);
        if(indexFound!==-1){
            return prev.filter(id=>id!==user_id)
        }
        return [...prev,user_id]
      })
    }

    useEffect(()=>{
        if(data){
            setName(data?.name??"");
            setDescription(data?.description??"");
            setSelected(data?.ids??[])
        }
    },[data])

    useEffect(()=>{
        if(type){
            setGroupType(type)
        }
    },[type])

    const reset=()=>{
        setName("");
        setSelected([]);
        setDescription('');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Implement group creation
        try {
            if(!name || !description || !selected.length){
                showError("Please provide all the fields")
                return;
            }
            let response=null;
            if(data?._id){
                response=await doPUT(ENDPOINTS.update(`${groupType}-recommendation-groups`),{
                    _id:data?._id,
                    name,
                    description,
                    ids:selected
                })
            }else{
                response=await doPOST(ENDPOINTS.create(`${groupType}-recommendation-groups`),{
                    name,
                    description,
                    ids:selected
                })
            }

            if(response.status==200){
                showMessage("success", "Created SuccessFully");
                onHide();
                if(groupType=='user'){
                    recommendationService.fetchUserGroups(true);
                }else{
                    recommendationService.fetchConsultantGroups(true);
                }
                reset();
            }
        } catch (error) {
            console.log(error);
            showError(error);
        }
    };

    return (
        <Modal fullscreen isOpen={show} toggle={onHide} className="modal-lg">
            <ModalHeader toggle={onHide}>Create New Group</ModalHeader>
            <ModalBody>
                <Form onSubmit={handleSubmit}>
                    <FormGroup className="d-flex justify-content-between">
                        <div>
                            <Label className="form-label">Group Type</Label>
                            <div>
                                <FormGroup check inline>
                                    <Input
                                        type="radio"
                                        name="groupType"
                                        value="user"
                                        checked={groupType === 'user'}
                                        onChange={e => setGroupType(e.target.value)}
                                    />
                                    <Label check>User Group</Label>
                                </FormGroup>
                                <FormGroup check inline>
                                    <Input
                                        type="radio"
                                        name="groupType"
                                        value="consultant"
                                        checked={groupType === 'consultant'}
                                        onChange={e => setGroupType(e.target.value)}
                                    />
                                    <Label check>Consultant Group</Label>
                                </FormGroup>
                            </div>
                        </div>
                        <div className="fs-5">
                            {selected?.length} {groupType}{selected?.length <= 1 ? "" : "s"} selected
                        </div>
                    </FormGroup>

                    <FormGroup>
                        <Label className="form-label">Name</Label>
                        <Input
                            type="text"
                            value={name}
                            onChange={e => setName(e.target.value)}
                            required
                        />
                    </FormGroup>

                    <FormGroup>
                        <Label className="form-label">Description</Label>
                        <Input
                            type="textarea"
                            value={description}
                            onChange={e => setDescription(e.target.value)}
                        />
                    </FormGroup>

                    {/* Member selection component here */}
                    {groupType == 'user' ? <Clients insidePane={true} selected={selected} onSelectionChange={onSelectionChange} setSelected={setSelected} />
                        : <Consultant hideHeader={true} insidePane={true} selected={selected} onSelectionChange={onSelectionChange} setSelected={setSelected} />}
                </Form>
            </ModalBody>
            <ModalFooter>
                <Button color="secondary" onClick={onHide}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit}>Create Group</Button>
            </ModalFooter>
        </Modal>
    );
}

export default CreateGroupModal;

